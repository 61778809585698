import React from 'react'
import MenuItem from '@mui/material/MenuItem'

const RenderSuggestion = ({ suggestion, index, itemProps, highlightedIndex }) => {
  const isHighlighted = highlightedIndex === index

  return suggestion ? (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isHighlighted ? 500 : 200,
        color: 'black',
        fontSize: '16px',
        background: isHighlighted ? 'rgba(236,236,236,1)' : '#fff',
        paddingLeft: '40px',
        paddingTop: '6px',
        height: '30px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '245',
        whiteSpace: 'nowrap',
      }}
    >
      {suggestion}
    </MenuItem>
  ) : null
}

export default RenderSuggestion
