import React from 'react'
import { FormControl, NativeSelect } from '@mui/material'
import {
  changedSlider,
  endSearchLoadingAnimation,
  searchError,
  searchEstimateNumber,
  searchSuccess,
  setInitialItemCount,
} from '@/actions/search'
import { filterSearchField } from '@/actions/search_field'
import SearchFormSelectBoxChip from '@/components/atoms/SearchFormSelectBoxChip/SearchFormSelectBoxChip'
import SearchFormSelectBoxMenuItem from '@/components/atoms/SearchFormSelectBoxMenuItem/SearchFormSelectBoxMenuItem'
import { ItemSearchApi } from '@/infrastructure-v2/plabase/plastic/item/search'
import { errorLog } from '@/lib/errorLog'

const SearchFormSelectBox = (props: any) => {
  const handleQuery = async (query: any) => {
    await props.dispatch(changedSlider(query))
    const searchObj = await props.getSearchObject()
    try {
      const response = await new ItemSearchApi().search(searchObj)
      if (!props.query.is_count) {
        props.dispatch(searchSuccess(response.items, props.query.page))
      }
      props.dispatch(searchEstimateNumber(response.count))
      if (props.query.type === 'initial_count') {
        props.dispatch(setInitialItemCount(response.count))
      }
      await new Promise((resolve) => setTimeout(resolve, 2000))
      props.dispatch(endSearchLoadingAnimation())
    } catch (err) {
      errorLog(err)
      props.dispatch(searchError())
    }
  }

  const onChange = (value: any, label: any, category: any) => {
    const { dispatch, search_field } = props
    let valueExist = false
    const query = { ...props.query }

    query[label].forEach((data: any) => {
      if (data === value) valueExist = true
    })
    if (!valueExist) {
      query[label].push(value)
      handleQuery(query)
    }
    search_field[`select${category}`] = query[label]
    dispatch(filterSearchField(search_field))
  }

  const handleChipDelete = (key: any, label: any, category: any) => {
    const { dispatch, search_field } = props
    const query = { ...props.query }
    const filteredChips = query[label].filter((property: any) => property !== key)

    query[label] = filteredChips
    handleQuery(query)
    search_field[`select${category}`] = query[label]
    dispatch(filterSearchField(search_field))
  }

  const { labels, language, languageName, query, search_field } = props
  const { search_box } = language.page
  const { label, category, chooseLable } = labels
  const filteredDatas = search_field[`filtered${category}`]

  return (
    <div>
      <FormControl sx={{ m: '10px', display: 'flex' }}>
        {filteredDatas &&
        typeof filteredDatas.length !== 'undefined' &&
        filteredDatas.length > 0 &&
        languageName !== null ? (
          <SearchFormSelectBoxMenuItem
            category={category}
            filteredDatas={filteredDatas}
            InputLabel={search_box[chooseLable]}
            label={label}
            onChange={onChange}
            query={query}
            search_box={search_box}
          />
        ) : (
          <NativeSelect value={[]} />
        )}
      </FormControl>
      <SearchFormSelectBoxChip
        handleChipDelete={handleChipDelete}
        label={label}
        category={category}
        chipDatas={query[label]}
      />
    </div>
  )
}

export default SearchFormSelectBox
