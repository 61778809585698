module.exports.cacheKeys = {
  top_list: 'top_list',
  recommend_list: 'recommend_list',
  faq_list: 'faq_list',
  article_list: 'article_list',
  trademark_list: 'trademark_list',
  categories: 'categories',
  tags: 'tags',
  resins: 'resins',
  article_post: 'article_post',
  article_faq: 'article_faq',
  article_trademark: 'article_trademark',
  item_count: 'item_count',
  search_field: 'search_field',
  article_list_category: 'article_list_category',
}
