import { ApiClient } from '@/infrastructure-v2/plabase/api-client'
import { ApiResponse, ApiQueryParam, ApiBody } from '@/infrastructure-v2/plabase/schema-util'
import { errorLog } from '@/lib/errorLog'

type ApiPath = '/api/plastic/items/search'

export type ItemSearchRequestBody = ApiBody<`${ApiPath}`, 'post'>
export type ItemSearchRequestBodyQuery = ItemSearchRequestBody['query']
export type ItemSearchResponse = ApiResponse<`${ApiPath}`, 'post'>
export type GetItemSearchConditionsResponse = ApiResponse<`${ApiPath}/conditions`, 'get'>
export type ListAutocompleteSuggestResponse = ApiResponse<`${ApiPath}/autocomplete/suggests`, 'get'>
export type ListAutocompleteSuggestRequestQuery = ApiQueryParam<
  `${ApiPath}/autocomplete/suggests`,
  'get'
>
export type ItemSearchMinMax = GetItemSearchConditionsResponse['minMax']
export type ItemSearchMakerSearchList = GetItemSearchConditionsResponse['makerSearchList']
export type ItemSearchProductSearchListList = GetItemSearchConditionsResponse['productSearchList']
export type ItemSearchResinSearchListList = GetItemSearchConditionsResponse['resinSearchList']
export type ItemSearchIsoSearchListList = GetItemSearchConditionsResponse['isoSearchList']
export type ItemSearchFlameRatingSearchListList =
  GetItemSearchConditionsResponse['flameRatingSearchList']

export const defaultQuery: ItemSearchRequestBodyQuery = {
  sortName: 'A0',
  sortOrder: 'desc',
  checkbox: {},
  fullText: '',
  resin_names: [],
  product_names: [],
  maker_names: [],
  iso_names: [],
  flame_ratings: [],
  tags: [],
  includeNull: 0,
  isFullItems: false,
  min_max: {},
}

export class ItemSearchApi {
  private readonly api: ApiClient

  constructor() {
    this.api = new ApiClient()
  }

  async search(
    query: ItemSearchRequestBodyQuery = defaultQuery,
    page = 1,
  ): Promise<ItemSearchResponse> {
    try {
      const response = await this.api.post<ItemSearchResponse, ItemSearchRequestBody>(
        '/plastic/items/search',
        { query, page },
      )

      return response.data
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async getAutocompleteSuggests(value: string): Promise<ListAutocompleteSuggestResponse> {
    try {
      const response = await this.api.get<
        ListAutocompleteSuggestResponse,
        ListAutocompleteSuggestRequestQuery
      >('/plastic/items/search/autocomplete/suggests', {
        value,
      })

      return response.data
    } catch (err) {
      errorLog(err)
      throw err
    }
  }

  async findSearchConditions(): Promise<GetItemSearchConditionsResponse> {
    try {
      const response = await this.api.get<GetItemSearchConditionsResponse>(
        '/plastic/items/search/conditions',
      )

      return response.data
    } catch (err) {
      errorLog(err)
      throw err
    }
  }
}
