import { CLOSE_FULLSCREEN_DIALOG, OPEN_FULLSCREEN_DIALOG } from '../constants'

export const handleFullScreenDialogOpen = (name = '') => ({
  type: OPEN_FULLSCREEN_DIALOG,
  name,
})

export const handleFullScreenDialogClose = () => ({
  type: CLOSE_FULLSCREEN_DIALOG,
})
