import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, Paper, Typography } from '@mui/material/'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useRouter } from 'next/router'
import { Dispatch } from 'redux'
import { handleFullScreenDialogClose } from '@/actions/dialog'
import {
  searchEstimateNumber,
  searchSuccess,
  searchError,
  setInitialItemCount,
  endSearchLoadingAnimation,
} from '@/actions/search'
import { getSearchFieldSuccess } from '@/actions/search_field'
import SearchFormAutoComplete from '@/components/molecules/SearchFormAutoComplete/SearchFormAutoComplete'
import SearchFormSelectBox from '@/components/molecules/SearchFormSelectBox/SearchFormSelectBox'
import {
  GetItemSearchConditionsResponse,
  ItemSearchApi,
} from '@/infrastructure-v2/plabase/plastic/item/search'
import { errorLog } from '@/lib/errorLog'

type Props = {
  authenticated: boolean
  checkbox: any
  dispatch: Dispatch<any>
  header: any
  language: any
  query: any
  search: any
  search_field: any
  searchConditions: GetItemSearchConditionsResponse
  user: any
}

function SearchForm(props: Props) {
  const classes = useStyles({})
  const router = useRouter()

  useEffect(() => {
    props.dispatch(
      getSearchFieldSuccess({
        makers: props.searchConditions.makerSearchList,
        resin_names: props.searchConditions.resinSearchList,
        product_names: props.searchConditions.productSearchList,
        flame_ratings: props.searchConditions.flameRatingSearchList,
        iso_names: props.searchConditions.isoSearchList,
      }),
    )
    props.dispatch(searchEstimateNumber(props.searchConditions.itemInitialCount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSearchObject = () => {
    const searchObj = {
      checkbox: props.checkbox,
      ...props.query,
      includeNull: props.user.user_info?.includeNull === 1 ? 1 : 0,
    }

    return searchObj
  }

  const handleSubmit = async () => {
    const searchObj = getSearchObject()
    try {
      const response = await new ItemSearchApi().search(searchObj)
      if (!props.query.is_count) {
        props.dispatch(searchSuccess(response.items, props.query.page))
      }
      props.dispatch(searchEstimateNumber(response.count))
      if (props.query.type === 'initial_count') {
        props.dispatch(setInitialItemCount(response.count))
      }
      await new Promise((resolve) => setTimeout(resolve, 2000))
      props.dispatch(endSearchLoadingAnimation())
      router.push('/core')
      props.dispatch(handleFullScreenDialogClose())
    } catch (err) {
      errorLog(err)
      props.dispatch(searchError())
    }
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        className={props.header.hideHeader ? classes.hideTitle : classes.title}
      >
        プラスチックDB検索
      </Typography>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: '2px', my: '9px', mx: '2px' }}>
              <SearchFormAutoComplete {...props} getSearchObject={getSearchObject} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: '2px', my: '9px', mx: '2px' }}>
              <SearchFormSelectBox
                labels={{
                  label: 'maker_names',
                  category: 'Makers',
                  chooseLable: 'choose_maker',
                }}
                {...props}
                getSearchObject={getSearchObject}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: '2px', my: '9px', mx: '2px' }}>
              <SearchFormSelectBox
                labels={{
                  label: 'resin_names',
                  category: 'Resins',
                  chooseLable: 'choose_resin',
                }}
                {...props}
                getSearchObject={getSearchObject}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: '2px', my: '9px', mx: '2px' }}>
              <SearchFormSelectBox
                labels={{
                  label: 'product_names',
                  category: 'Products',
                  chooseLable: 'choose_product',
                }}
                {...props}
                getSearchObject={getSearchObject}
              />
            </Paper>
          </Grid>
        </Grid>
        <div>
          <div className={classes.buttonContainer}>
            <Typography mt={2} textAlign="center">
              {props.language.page.home.estimate_search_result}{' '}
              <span>
                {props.search.item_count
                  ? props.search.item_count
                  : '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}
              </span>
              {props.language.page.home.number}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="#"
              sx={{ m: 1, textAlign: 'center' }}
              onClick={handleSubmit}
            >
              {props.language.page.home.search}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(3),
    },
    hideTitle: {
      display: 'none',
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
    title: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
  }),
)

const mapStateToProps = (state: any) => ({
  authenticated: state.user.authenticated,
  checkbox: state.search.checkbox,
  header: state.header,
  language: state.language.language,
  query: state.search.query,
  search: state.search,
  search_field: state.search_field,
  user: state.user,
})

export default connect(mapStateToProps)(SearchForm)
