import React from 'react'
import Chip from '@mui/material/Chip'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const RenderChip = ({ handleRequestDelete, item }: any) => {
  const classes = useStyles()

  if (typeof item === 'string' && item.trim().length > 0) {
    return (
      <Chip
        label={item}
        onDelete={() => handleRequestDelete(item)}
        classes={{ deletable: classes.deletable, label: classes.label }}
        key={item}
      />
    )
  }

  return null
}

const useStyles = makeStyles(() =>
  createStyles({
    deletable: {
      fontFamily:
        '游ゴシック体,ヒラギノ角ゴ Pro,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,sans-serif',
      margin: 4,
      height: 'auto',
      maxWidth: '99%',
    },
    label: {
      display: 'block !important',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  }),
)

export default RenderChip
