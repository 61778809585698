import React from 'react'
import { Box, NativeSelect } from '@mui/material'

const SearchFormSelectBoxMenuItem = ({
  category,
  filteredDatas,
  InputLabel,
  label,
  onChange,
}: any) => {
  return (
    <NativeSelect
      value={InputLabel}
      onChange={(e) => {
        onChange(e.target.value, label, category)
      }}
    >
      <Box component="option" sx={{ whiteSpace: 'normal' }} value={InputLabel}>
        {InputLabel}
      </Box>
      {filteredDatas.map((filteredData: any) => {
        if (typeof filteredData !== 'undefined' && filteredData !== null) {
          return (
            <Box
              component="option"
              sx={{ whiteSpace: 'normal' }}
              value={filteredData.name}
              key={filteredData.name}
            >
              {filteredData.name}
            </Box>
          )
        }

        return false
      })}
    </NativeSelect>
  )
}

export default SearchFormSelectBoxMenuItem
