/* eslint-disable no-param-reassign */
import { cacheKeys } from '@/lib/cacheKeys'
import { errorLog } from '@/lib/errorLog'
import { fetcher } from '@/lib/fetcher'
import * as types from '../constants'
import { handleMessageOpen } from './snackbar'

export const getSearchFieldSuccess = (search_field) => ({
  type: types.GET_SEARCH_FIELD_SUCCESS,
  search_field,
})

function filterValues({
  filterFields,
  filterIndex,
  filterProperty,
  selectFields,
  selectFieldLists,
}) {
  // set of other dropdown values in bind list
  const searchList = {
    flame: 'flame_rating_search_list',
    iso: 'iso_search_list',
    maker: 'maker_search_list',
    product: 'product_search_list',
    resin: 'resin_search_list',
  }

  filterFields[filterIndex] = filterFields[filterIndex].filter((filteredField) => {
    let includesField = false
    selectFieldLists.map((selectFieldList) => {
      // all other bind list values are filtered according to checked values
      if (
        selectFieldList[searchList[filterIndex]].includes(
          filteredField[filterProperty[filterIndex]],
        )
      ) {
        includesField = true
      }

      // checked values are included in the current dropdown
      if (selectFields[filterIndex].includes(filteredField[filterProperty[filterIndex]])) {
        includesField = true
      }

      return true
    })

    return includesField
  })
}

export function filterSearchField({
  flame,
  iso,
  maker,
  product,
  resin,
  selectFlames,
  selectIsos,
  selectMakers,
  selectProducts,
  selectResins,
}) {
  // set of all values in dropdown list
  const filterFields = { flame, iso, maker, product, resin }
  // set of checked values in dropdown list
  const selectFields = {
    flame: selectFlames,
    iso: selectIsos,
    maker: selectMakers,
    product: selectProducts,
    resin: selectResins,
  }

  // set of property name for matching the bind list
  const filterProperty = {
    flame: 'name',
    iso: 'name',
    maker: 'value',
    product: 'value',
    resin: 'value',
  }
  for (const [selectIndex, selectField] of Object.entries(selectFields)) {
    if (typeof selectField.length !== 'undefined' && selectField.length > 0) {
      // set of bind list values based on checked values
      const selectFieldLists = filterFields[selectIndex].filter((filterField) =>
        selectField.includes(filterField.name),
      )

      for (const filterIndex of Object.keys(filterFields)) {
        // current dropdown is skipped from filtering
        if (selectIndex === filterIndex) continue

        // all other values are filtered according to checked values
        filterValues({
          filterFields,
          filterIndex,
          filterProperty,
          selectFields,
          selectFieldLists,
        })
      }
    }
  }

  return {
    type: types.FILTER_SEARCH_FIELD,
    filterFields,
    selectFields,
  }
}

export const getSearchField =
  (language = 'jp', message) =>
  (dispatch) => {
    return fetcher
      .get('/v1/get_search_field', {
        language,
        cacheKey: cacheKeys.search_field,
      })
      .then((response) => {
        dispatch(getSearchFieldSuccess(response.data))
      })
      .catch((err) => {
        errorLog(err)
        dispatch(handleMessageOpen(message.validation_error, 'error', 5000))
      })
  }
