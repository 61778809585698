import React from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const RenderLabel = ({ suggestionLabel }: any) => {
  const classes = useStyles()

  return suggestionLabel ? (
    <div className={classes.autosuggest_container}>
      <div className={classes.autosuggest_label_left} key={suggestionLabel}>
        {suggestionLabel}
      </div>
      <div className={classes.autosuggest_label_right} />
    </div>
  ) : null
}

const useStyles = makeStyles(() =>
  createStyles({
    autosuggest_container: {
      width: '100%',
      float: 'left',
      position: 'relative',
    },
    autosuggest_label_right: {
      position: 'absolute',
      top: '16px',
      borderBottom: 'thin solid rgba(0, 0, 0, 0.3)',
      right: 0,
      zIndex: -1,
      width: '100%',
    },
    autosuggest_label_left: {
      fontWeight: 200,
      color: 'rgba(0, 0, 0, 0.3)',
      background: '#fff',
      padding: '6px 7px 6px 15px',
      height: '30px',
      fontSize: '16px',
      float: 'left',
      textAlign: 'left',
    },
  }),
)
export default RenderLabel
