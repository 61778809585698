import React from 'react'
import { Chip } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const RenderChip = ({ handleChipDelete, chipDatas, label, category }: any) => {
  const classes = useStyles()

  return chipDatas.map((chipData: any, index: number) => {
    return (
      <Chip
        label={chipData}
        key={index}
        classes={{ deletable: classes.deletable, label: classes.label }}
        onDelete={() => handleChipDelete(chipData, label, category)}
      />
    )
  })
}

const useStyles = makeStyles(() =>
  createStyles({
    deletable: {
      fontFamily:
        '游ゴシック体,ヒラギノ角ゴ Pro,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,sans-serif',
      margin: 4,
      height: 'auto',
      maxWidth: '99%',
    },
    label: {
      display: 'block !important',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  }),
)

export default RenderChip
