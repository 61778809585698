import { errorLog } from '@/lib/errorLog'
import { fetcher } from '@/lib/fetcher'
import * as types from '../constants'
import { handleMessageOpen } from './snackbar'

export const searchSuccess = (data, page = '1') => ({
  type: types.SEARCH_SUCCESS,
  data,
  page,
})

export const searchError = () => ({ type: types.SEARCH_ERROR })

export const endSearchLoadingAnimation = () => ({
  type: types.END_SEARCH_LOADING_ANIMATION,
})

export function fetchSearchItems(
  props,
  language = 'jp',
  sortName = 'A0',
  sortOrder = 'desc',
  page = 1,
  is_count = false,
  user = 'user',
  type = 'search_count',
) {
  const query = {
    ...props,
    page,
    language,
    is_count,
    sortName,
    sortOrder,
    user,
  }

  return (dispatch) => {
    return fetcher
      .post('/v1/search', query)
      .then((response) => {
        if (is_count === false) {
          dispatch(searchSuccess(response.data.items, page))
        }
        dispatch(searchEstimateNumber(response.data.itemCount))
        if (type === 'initial_count') {
          dispatch(setInitialItemCount(response.data.itemCount))
        }
        setTimeout(() => {
          dispatch(endSearchLoadingAnimation())
        }, 2000)
      })
      .catch((err) => {
        errorLog(err)
        dispatch(searchError('Oops! Something went wrong!'))
      })
  }
}

export const setInitialItemCount = (item_count) => ({
  type: types.INITIAL_ITEM_COUNT,
  item_count,
})

export const searchEstimateNumber = (item_count) => ({
  type: types.SEARCH_ITEM_ESTIMATE_NUMBER,
  item_count,
})

export const changedSlider = (query) => ({
  type: types.CHANGED_SLIDER,
  query,
})

const saveSearchSuccess = (saveSearchQueryId) => ({
  type: types.SAVE_SEARCH_SUCCESS,
  saveSearchQueryId,
})

export const updateVisitWay = (visitWay) => ({
  type: types.VISIT_WAY,
  visitWay,
})

const saveUserSearchSuccess = () => ({
  type: types.SAVE_USER_SEARCH_SUCCESS,
})

/* eslint-disable no-param-reassign */
export const saveSearchQuery = (data, message) => (dispatch) => {
  delete data.created_time
  delete data.updated_time

  return fetcher
    .post('/v1/save_search_query', {
      search: data,
    })
    .then((response) => {
      dispatch(saveSearchSuccess(response.data.saveSearchQueryId))
    })
    .catch((err) => {
      errorLog(err)
      dispatch(handleMessageOpen(message.validation_error, 'error', 5000))
    })
}

/* eslint-disable no-underscore-dangle */
export const saveOwnSearchQuery = (data, user_id, message) => (dispatch) => {
  delete data.created_time
  delete data.updated_time
  delete data._userSaveSearch_id

  return fetcher
    .post('/v1/save_own_search_query', { search: data })
    .then(() => {
      dispatch(saveUserSearchSuccess())
      dispatch(getSaveUserSearch(user_id))
    })
    .catch((err) => {
      errorLog(err)
      dispatch(handleMessageOpen(message.validation_error, 'error', 5000))
    })
}
/* eslint-enable no-underscore-dangle */
/* eslint-enable no-param-reassign */

export const getUserSearchSuccess = (saved_user_search) => ({
  type: types.GET_USER_SEARCH_SUCCESS,
  saved_user_search,
})
export const getSaveUserSearch = (data) => (dispatch) => {
  return fetcher
    .post('/v1/get_user_search', data)
    .then((response) => {
      dispatch(getUserSearchSuccess(response.data))
    })
    .catch((err) => {
      errorLog(err)
      throw err
    })
}

export const getFullTextSuccess = (fulltexts) => ({
  type: types.GET_FULLTEXT_SUCCESS,
  fulltexts,
})
