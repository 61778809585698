import * as types from '../constants'

export const setMetaTags = (metaTags) => ({
  type: types.SET_META_TAGS,
  metaTags,
})

export const toggleHeader = (hideHeader) => ({
  type: types.TOGGLE_HEADER,
  hideHeader,
})
