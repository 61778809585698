import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Downshift from 'downshift'
import { toggleHeader } from '@/actions/header'
import {
  changedSlider,
  saveSearchQuery,
  getFullTextSuccess,
  endSearchLoadingAnimation,
  searchEstimateNumber,
  searchSuccess,
  setInitialItemCount,
  searchError,
} from '@/actions/search'
import SearchFormAutoCompleteChip from '@/components/atoms/SearchFormAutoCompleteChip/SearchFormAutoCompleteChip'
import SearchFormAutoCompleteInput from '@/components/atoms/SearchFormAutoCompleteInput/SearchFormAutoCompleteInput'
import SearchFormAutoCompleteItem from '@/components/atoms/SearchFormAutoCompleteItem/SearchFormAutoCompleteItem'
import SearchFormAutoCompleteLabel from '@/components/atoms/SearchFormAutoCompleteLabel/SearchFormAutoCompleteLabel'
import { ItemSearchApi } from '@/infrastructure-v2/plabase/plastic/item/search'
import { errorLog } from '@/lib/errorLog'

const SearchFormAutoComplete = (props: any) => {
  const [searchText, setSearchText] = useState('')
  const classes = useStyles()

  useEffect(() => {
    if (props.query.fullText === '') {
      setSearchText('')
    }

    return () => {
      props.dispatch(toggleHeader(false))
    }
  }, [props.search.fullText]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewRequest = async (event: any) => {
    const { value } = event.target
    if (typeof value === 'string' && value.trim().length >= 0) {
      setSearchText(value)
      const { dispatch } = props

      try {
        const { suggests } = await new ItemSearchApi().getAutocompleteSuggests(value)
        dispatch(getFullTextSuccess(suggests))
      } catch (err) {
        errorLog(err)
      }
    }
  }

  const handleSelect = (value: any) => {
    let searchFulltext
    if (typeof value === 'string' && value.trim().length > 0) {
      const chipData = props.search.query.fullText.split(',')
      if (chipData.indexOf(value) === -1) {
        searchFulltext = `${props.search.query.fullText},${value}`
        const query = { ...props.search.query }
        query.fullText = searchFulltext
        handleQuery(query)
      }
      setSearchText('')
    }
  }

  const handleRequestDelete = (key: any) => {
    const searchArry = props.search.query.fullText.split(',')
    let newData = []
    newData = searchArry.filter((check: any) => check !== key)

    const searchFulltext = newData.join(',')
    const query = { ...props.search.query }
    query.fullText = searchFulltext
    handleQuery(query)
  }

  const handleQuery = async (query: any) => {
    const { dispatch, language } = props
    const { message } = language.page
    const updatedQuery = { ...query }
    await dispatch(changedSlider(query))
    const searchObj = {
      ...updatedQuery,
    }
    try {
      const response = await new ItemSearchApi().search(searchObj)
      if (!props.query.is_count) {
        dispatch(searchSuccess(response.items, props.query.page))
      }
      dispatch(searchEstimateNumber(response.count))
      if (props.query.type === 'initial_count') {
        dispatch(setInitialItemCount(response.count))
      }
      await new Promise((resolve) => setTimeout(resolve, 2000))
      dispatch(endSearchLoadingAnimation())
    } catch (err) {
      errorLog(err)
      dispatch(searchError())
    }

    await dispatch(saveSearchQuery(searchObj, message))
  }

  const handleStateChange = (changes: any) => {
    const { dispatch } = props
    const { type } = changes
    let hideHeader
    switch (type) {
      case '__autocomplete_change_input__':
      case 9:
        hideHeader = true
        break
      case '__autocomplete_click_item__':
      case '__autocomplete_blur_input__':
      case '__autocomplete_touchstart__':
      case '__autocomplete_keydown_enter__':
      case 6:
      case 7:
      case 8:
      case 14:
        hideHeader = false
        break
      default:
    }
    if (typeof hideHeader !== 'undefined') {
      dispatch(toggleHeader(hideHeader))
    }
  }

  const { search, language } = props
  const { query, fulltexts } = search
  const { search_box } = language.page
  const { fullText } = query
  const selectedItem = fullText.split(',')

  return (
    <div>
      <div className={classes.wrapper}>
        <Downshift
          id="downshift-multiple"
          inputValue={searchText}
          onChange={handleSelect}
          onStateChange={handleStateChange}
          selectedItem={selectedItem}
        >
          {({ getInputProps, getItemProps, isOpen, highlightedIndex }) => {
            const listIndex = 1

            return (
              <div className={classes.container}>
                <SearchFormAutoCompleteInput
                  getInputProps={getInputProps}
                  handleNewRequest={handleNewRequest}
                  search_box={search_box}
                  selectedItem={selectedItem}
                />
                {isOpen ? (
                  <Paper className={classes.paper}>
                    <SearchFormAutoCompleteItem
                      suggestion={searchText}
                      index={0}
                      itemProps={getItemProps({
                        item: searchText,
                      })}
                      highlightedIndex={highlightedIndex}
                    />
                    {fulltexts.map((text: any, key: number) => (
                      <div key={`${key}${text.title}`}>
                        <SearchFormAutoCompleteLabel suggestionLabel={text.title} />
                        {text.suggestions.map((suggestion: any, suggestionIndex: number) => {
                          return (
                            <SearchFormAutoCompleteItem
                              suggestion={suggestion}
                              key={`${key}${text.title}${suggestionIndex}`}
                              index={listIndex + 1}
                              itemProps={getItemProps({ item: suggestion })}
                              highlightedIndex={highlightedIndex}
                            />
                          )
                        })}
                      </div>
                    ))}
                  </Paper>
                ) : null}
              </div>
            )
          }}
        </Downshift>
      </div>
      {selectedItem.map((item: any, key: number) => (
        <SearchFormAutoCompleteChip
          key={`${key}${item}`}
          handleRequestDelete={handleRequestDelete}
          item={item}
        />
      ))}
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      maxHeight: 370,
      overflowY: 'scroll',
      zIndex: 3,
      background: '#fff',
      position: 'absolute',
      marginLeft: '6px',
      boxShadow: '0px 2px 5px #ccc',
      width: '100%',
    },
    wrapper: {
      flexWrap: 'wrap',
      margin: '0 0 7px 0',
      marginRight: '16px',
      position: 'relative',
    },
  }),
)

export default SearchFormAutoComplete
