import React, { Fragment } from 'react'
import TextField from '@mui/material/TextField'

function renderInput(inputProps: any) {
  const { InputProps, ref, ...other } = inputProps

  return (
    <TextField
      variant="standard"
      InputProps={{
        inputRef: ref,
        sx: {
          root: { flexWrap: 'wrap' },
          input: { width: 'auto', flexGrow: 1 },
        },
        ...InputProps,
      }}
      {...other}
      sx={{ ml: '6px' }}
    />
  )
}

const SearchFormAutoCompleteInput = (props: any) => {
  const { getInputProps, handleNewRequest, search_box } = props

  return (
    <Fragment>
      {renderInput({
        fullWidth: true,
        InputProps: getInputProps({
          onChange: handleNewRequest,
          placeholder: search_box.text_search_example,
        }),
        label: search_box.text_search,
      })}
    </Fragment>
  )
}

export default SearchFormAutoCompleteInput
